import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import api from '../../shared/lib/api';
import { IDokumentStatistikk, IKundeLinkItem, IVeilederIArbeid, VeilederType } from '../../shared/lib/types';
import VeiledereIArbeid from './VeiledereIArbeid';
import TilbakeTil from '../Tilbake';
import Spinner from '../../spinner/Spinner';
import FeilmeldingSide from '../../feilside/FeilmeldingSide';
import { ArrowRight } from '@phosphor-icons/react/dist/ssr';
import toast from 'react-hot-toast';

type TParams = {
    kundeId: string;
};

const Kundeside = () => {
    const { kundeId } = useParams<TParams>();
    const [kundeNavn, setKundeNavn] = useState<string>();
    const [veiledereIArbeid, setVeiledereIArbeid] = useState<Array<IVeilederIArbeid>>();
    const [dokumentStatistikk, setDokStatistikk] = useState<IDokumentStatistikk>({ antallDokumenter: 0 });
    const [egetforetak, setEgetforetak] = useState<boolean>(false);
    const [egetRegnskapsforetakRedigering, setEgetRegnskapsforetakRedigering] = useState<IKundeLinkItem>();
    const [loading, setLoading] = useState<boolean>(true);
    const [serverErrorCode, setServerErrorCode] = useState<number>(0);

    function onDeleteVeilederIArbeid(veilederId: string) {
        setLoading(true);

        api.deleteVeilederIArbeid(kundeId, veilederId)
            .then(() => {
                const filteredVeiledere = veiledereIArbeid.filter((veileder) => veileder.veilederId !== veilederId);
                setVeiledereIArbeid(filteredVeiledere);
            })
            .catch((err) => {
                toast.error(`Kunne ikke slette veileder: ${veilederId}`);
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        (() => {
            Promise.all([
                api.getInnloggetBruker().then(async (res) => {
                    if (res.regnskapsforetakId === kundeId) {
                        const [redigering, dokumentStatistikk] = await Promise.all([
                            api.getEgetRegnskapsforetakRedigering(),
                            api.getDokumentStatistikkForEgetRegnskapsforetak(),
                        ]);
                        setEgetRegnskapsforetakRedigering(redigering);
                        setDokStatistikk(dokumentStatistikk);
                        setKundeNavn(res.regnskapforetakNavn);
                        setEgetforetak(true);
                    } else {
                        const [kunde, dokumentStatistikk] = await Promise.all([api.getKunde(kundeId), api.getDokumentStatistikkForKunde(kundeId)]);
                        setKundeNavn(kunde.navn);
                        setDokStatistikk(dokumentStatistikk);
                    }
                }),
                api.getVeiledereIArbeid(kundeId).then((res) => setVeiledereIArbeid(res)),
            ])
                .catch((error) => {
                    setServerErrorCode(error.status);
                })
                .finally(() => setLoading(false));

            document.title = 'KS Komplett - Kundeside';
        })();
    }, [kundeId]);

    if (loading) {
        return <Spinner loading={loading} />;
    }

    if (serverErrorCode) {
        return <FeilmeldingSide errorCode={serverErrorCode} url="/kunder" />;
    }

    return (
        <div className="kundeside">
            <div className="page-header">
                <div className="container">
                    <div className="col-md-6 d-flex align-items-center">
                        <span className={`icon ${egetforetak ? 'icon-eget-regnskapsforetak' : 'icon-kundenavn-stor'}`} />
                        {kundeNavn && <h2>{kundeNavn}</h2>}
                    </div>

                    <div className="col-md-6 d-flex justify-content-end kundeside-tittel">
                        <span>Kundeside</span>
                    </div>
                </div>
            </div>
            <TilbakeTil url={`/kunder`} tekst="Tilbake til kundeoversikt" />
            <div className="page">
                <div className="container">
                    <div className="row mb-lg">
                        <div className="col-md-6">
                            {egetforetak ? (
                                egetRegnskapsforetakRedigering?.url && (
                                    <a
                                        href={egetRegnskapsforetakRedigering?.url}
                                        className="btn btn--secondary"
                                        target={egetRegnskapsforetakRedigering?.target}
                                    >
                                        {egetRegnskapsforetakRedigering?.text}
                                    </a>
                                )
                            ) : (
                                <Link to={`/kunder/${kundeId}/`} className="btn btn--secondary">
                                    Kundedetaljer
                                </Link>
                            )}
                        </div>

                        <div className="col-md-6 d-flex justify-content-end">
                            <div>
                                <div className="vstack">
                                    <Link to={`/kunder/${kundeId}/velg-veileder?t=${VeilederType.veileder.toString()}`} className="btn btn--primary">
                                        Start veileder for {egetforetak ? 'eget foretak' : 'kunde'}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="kundeside-generertdokumenter  d-flex align-items-end gap-5">
                                <Link className="dokument-lenke" to={`/kunder/${kundeId}/genererte-dokumenter`}>
                                    Til Dokumentarkiv (<span className="dokument-antall">{dokumentStatistikk.antallDokumenter}</span>)
                                </Link>
                                <ArrowRight size={28} color="red" />
                            </div>
                        </div>
                    </div>
                    <VeiledereIArbeid kundeId={kundeId} veiledere={veiledereIArbeid} onDeleteVeilederIArbeidClick={onDeleteVeilederIArbeid} />
                </div>
            </div>
        </div>
    );
};

export default Kundeside;
