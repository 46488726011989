import React from 'react';
import { Link } from 'react-router-dom';
import { IVeilederIArbeid } from '../../shared/lib/types';
import { format, parseISO } from 'date-fns';
import Modal from '../../../modal/Modal';
import ModalChildContent from '../../../modal/ModalChildContent';
import { replaceSpecialChars } from '../../../../shared/lib/utils';

export interface TProps {
    kundeId: string;
    veiledere: Array<IVeilederIArbeid>;
    onDeleteVeilederIArbeidClick(veilederId: string): void;
}

const VeiledereIArbeid = (props: TProps) => {
    const veiledereIArbeid = props.veiledere;

    return (
        <div>
            <h3>VEILEDERE I ARBEID</h3>
            <table className="table--veiledere-i-arbeid">
                <thead>
                    <tr>
                        <td>Navn</td>
                        <td>Lagret</td>
                        <td>
                            <span className="sr-only">Funksjoner</span>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {veiledereIArbeid &&
                        veiledereIArbeid.map((veileder) => {
                            return (
                                <tr key={veileder.id}>
                                    <td>{veileder.tittel}</td>
                                    <td>{format(parseISO(veileder.sistOppdatertUtc), 'dd.MM.yyyy HH.mm')}</td>
                                    <td>
                                        <div className="table__actions">
                                            <Link
                                                to={`/kunder/${props.kundeId}/veileder/${veileder.veilederId}/${replaceSpecialChars(
                                                    veileder.tittel,
                                                    '-'
                                                )}`}
                                                className="btn btn--secondary btn--sm"
                                            >
                                                Gjenoppta
                                            </Link>
                                            <Modal
                                                triggerText="Slett"
                                                triggerClassName="btn btn--secondary btn--sm"
                                                footer={
                                                    <>
                                                        <button
                                                            className="btn btn--secondary btn--sm"
                                                            data-bs-dismiss="modal"
                                                            onClick={() => {
                                                                props.onDeleteVeilederIArbeidClick(veileder.veilederId);
                                                            }}
                                                        >
                                                            Slett
                                                        </button>
                                                        <button className="btn btn--primary btn--sm" data-bs-dismiss="modal">
                                                            Avbryt
                                                        </button>
                                                    </>
                                                }
                                                children={
                                                    <ModalChildContent
                                                        tittel="Slette veileder"
                                                        melding={`Er du sikker på at du vil slette veileder "${veileder.tittel}"?`}
                                                    />
                                                }
                                            ></Modal>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        </div>
    );
};

export default VeiledereIArbeid;
